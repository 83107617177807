import { downLoadFile } from '@/tools/common';
import { message } from 'ant-design-vue';
import { ApiConfig, ApiMethodType, RequestType } from './types';
import './interceptor';
import { request } from './request';

export * from './types';
export * from './token';

/**
 * 请求封装
 * @param {string} url URL地址
 * @param {object} params 参数
 * @param {ApiConfig} config
 */
const requestCreator =
  <T = void>(type: ApiMethodType): RequestType<T> =>
  (url, params = {}, config = {}) =>
    request({
      method: type,
      url,
      params,
      config: {
        responseType: 'json',
        ...config,
      },
    });

export const get = requestCreator(ApiMethodType.get);
export const post = requestCreator(ApiMethodType.post);
export const deletes = requestCreator(ApiMethodType.delete);
export const put = requestCreator(ApiMethodType.put);

/** 通用下载方法 */
export function download(
  url,
  params,
  {
    filename = '',
    method = ApiMethodType.post,
    ...config
  }: {
    filename?: string;
    method?: ApiMethodType;
  } & ApiConfig<any, any> = {
    filename: '',
    method: ApiMethodType.post,
  },
) {
  return request({
    url,
    params,
    config: {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      responseType: 'blob',
      ...config,
    },
    ...config,
    method: method || 'POST',
  })
    .then(data => {
      downLoadFile(filename || data.filename || '导出文件', data.blob);
    })
    .catch(() => {
      message.error('下载文件出现错误，请联系管理员！');
    });
}
